var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"svv-search-form"},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.indexName,"search-function":_vm.searchFunction}},[_vm._l((_vm.selectsOptions),function(selectsOption){return _c('div',{key:selectsOption.attribute,class:[
        'ssws-search-box',
        'ssws-search-box--' + selectsOption.name,
        selectsOption.vs ]},[_c('div',{staticClass:"search-panel__filters ssws_search_sec"},[_c('div',{staticClass:"ssws_search_panel-tab"},[_c('label',{key:selectsOption.attribute,staticClass:"labelKeyoptin",attrs:{"for":selectsOption.attribute}},[_vm._v(" "+_vm._s(_vm.copy[selectsOption.labelKey])+" ")])]),_c('div',{staticClass:"ssws_search_input"},[(!_vm.weHave[selectsOption.attribute])?_c('div',{staticClass:"ssws_search_input ssws_search_input--Placeholder"},[_c('div',{staticClass:"multiselect"},[_c('div',{staticClass:"multiselect__select"}),_c('div',{staticClass:"multiselect__tags"})])]):_vm._e(),_c('ais-refinement-list',{attrs:{"attribute":selectsOption.attribute,"limit":_vm.filterLimit,"sort-by":_vm.itemOrder(selectsOption.orderOn),"transform-items":function (items) {
                return _vm.transformItems(items, selectsOption.attribute);
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var items = ref.items;
              var refine = ref.refine;
return _c('div',{},[_c('multiselect',{staticClass:"listboxsearch",attrs:{"id":selectsOption.attribute,"label":"label","aria-expanded":"false","track-by":"value","block-keys":['Tab'],"options":items,"multiple":true,"placeholder":_vm.copy[selectsOption.labelKey],"selectLabel":"","deselectLabel":"","selectedLabel":_vm.copy.selectedLabel},on:{"select":function (selectedOption, id) {
                    refine(selectedOption.value);
                  },"remove":function (removedOption, id) {
                    refine(removedOption.value);
                  }},model:{value:(_vm.selectedValues[selectsOption.attribute]),callback:function ($$v) {_vm.$set(_vm.selectedValues, selectsOption.attribute, $$v)},expression:"selectedValues[selectsOption.attribute]"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(" "+_vm._s(_vm.copy.noResults)+" ")])])],1)}}],null,true)})],1)])])}),_c('div',{staticClass:"ssws-search-box ssws-search-box--Submit"},[_c('div',{staticClass:"ssws_submit_sec"},[_c('a',{staticClass:"ssws_submit_btn",attrs:{"role":"button","tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buildUrlAndGo($event)},"click":_vm.buildUrlAndGo}},[_vm._v(_vm._s(_vm.copy.searchSubmitButton)+" ")])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }