<template>
  <div class="svv-search-form">
    <ais-instant-search 
        :search-client="searchClient" 
        :index-name="indexName"
        :search-function='searchFunction'>
      <div
        v-for="selectsOption in selectsOptions"
        :key="selectsOption.attribute"
        :class="[
          'ssws-search-box',
          'ssws-search-box--' + selectsOption.name,
          selectsOption.vs,
        ]"
      >
        <div class="search-panel__filters ssws_search_sec">
          <div class="ssws_search_panel-tab">
            <label
              :key="selectsOption.attribute"
              :for="selectsOption.attribute"
              class="labelKeyoptin"
            >
              {{ copy[selectsOption.labelKey] }}
            </label>
          </div>
          <div class="ssws_search_input">
            <div
              class="ssws_search_input ssws_search_input--Placeholder"
              v-if="!weHave[selectsOption.attribute]"
            >
              <div class="multiselect">
                <div class="multiselect__select"></div>
                <div class="multiselect__tags"></div>
              </div>
            </div>

            <ais-refinement-list
              :attribute="selectsOption.attribute"
              :limit="filterLimit"
              :sort-by="itemOrder(selectsOption.orderOn)"
              :transform-items="
                items => {
                  return transformItems(items, selectsOption.attribute);
                }
              "
            >
              <div slot-scope="{ items, refine }">
                <multiselect
                  :id="selectsOption.attribute"
                  label="label"
                  aria-expanded="false"
                  track-by="value"
                  v-model="selectedValues[selectsOption.attribute]"
                  @select="
                    (selectedOption, id) => {
                      refine(selectedOption.value);
                    }
                  "
                  @remove="
                    (removedOption, id) => {
                      refine(removedOption.value);
                    }
                  "
                  :block-keys="['Tab']"
                  :options="items"
                  :multiple="true"
                  :placeholder="copy[selectsOption.labelKey]"
                  selectLabel=""
                  deselectLabel=""
                  :selectedLabel="copy.selectedLabel"
                  class="listboxsearch"
                >
                  <span slot="noResult"> {{ copy.noResults }} </span>
                </multiselect>
              </div>
            </ais-refinement-list>
          </div>
        </div>
      </div>
      <div class="ssws-search-box ssws-search-box--Submit">
        <div class="ssws_submit_sec">
          <a
            role="button"
            tabindex="0"
            @keyup.enter="buildUrlAndGo"
            @click="buildUrlAndGo"
            class="ssws_submit_btn"
            >{{ copy.searchSubmitButton }}
          </a>
        </div>
      </div>
    </ais-instant-search>
  </div>
</template>
<script>
/* global svvSearchForm */
/* eslint-disable vue/no-unused-components, prettier/prettier, no-console */
import Multiselect from 'vue-multiselect';
import algoliasearch from 'algoliasearch';
import selectsOptions from './utility/selects';
import './style.css';
import jQuery from 'jquery';

const localize = function(key) {
  if (typeof svvSearchForm === 'undefined') {
    return false;
  }

  if (typeof svvSearchForm[key] === 'undefined') {
    return false;
  }

  return svvSearchForm[key];
};


export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      selectsOptions: selectsOptions,
      selectedValues: {},
      searchClient: algoliasearch(
        localize('svb_algolia_app') || '',
        localize('svb_algolia_api_key') || ''
      ),
      searchFunction(helper){
            
            if (typeof window.algolia.client_proxy === 'string'){
                this.client.hosts.read = Array.prototype.map.call(
                    this.client.hosts.read,
                    (host) => host.indexOf( window.algolia.client_proxy +'?url=' ) === -1 
                                ? window.algolia.client_proxy + '?url=' + host 
                                : host
                );
            }

            helper.search();
      },
      indexName: localize('svb_algolia_index') || '',
      filterLimit: 100, //very high number
      weHave : {},
      targetPage: localize('svb_target_url') || '',
      copy: {
        noResults: localize('no_results') || 'No Results',
        tjanstLabel: localize('tjanst_label') || 'Service',
        lanLabel: localize('lan_label') || 'Region',
        kommunLabel: localize('kommun_label') || 'Municipality',
        tilbudLabel: localize('tilbud_label') || 'Offer',
        regionLabel: localize('region_label') || 'Region',
        kommuneLabel: localize('kommune_label') || 'Municipality',
        searchSubmitButton: localize('search_submit_button') || 'Search',
        sortOn: localize('search_sortOn') || 'name',
        selectedLabel: localize('selected_label') || 'Select',
      },
    };
  },
  mounted() {

    this.$nextTick(function() {
      jQuery('.multiselect__input').attr("aria-activedescendant","");
      setInterval(()=>{
        const selectedtag= jQuery('.multiselect__tag span');
        const selecteddelete= jQuery('.multiselect__tag i');
         selectedtag.attr("role","option");
         selectedtag.attr("tabindex","0");
         selecteddelete.attr("role","button");
         selecteddelete.attr("tabindex","0");
         selecteddelete.attr("aria-hidden","false");
         if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          selecteddelete.attr("aria-label","Tryck två gånger för att ta bort filtret");
        }else{
          selecteddelete.attr("aria-label","tryck enter för att ta bort");
        }
      },300);
      setTimeout(()=>{
        jQuery(".multiselect__input").each(function(){
          jQuery(this).removeAttr("autocomplete");
        });
        jQuery(document).on('focus','.ais-RefinementList .multiselect',function(){
          jQuery(this).attr("aria-expanded",true);
          jQuery(this).find("input").attr("autocomplete","off");
        });
        jQuery(document).on('blur','.ais-RefinementList .multiselect',function(){
          jQuery(this).attr("aria-expanded",false);
          jQuery(this).find("input").attr("autocomplete","off");
        });

        const inputButton =jQuery('.listboxsearch');
              inputButton.keyup(function(event){
                console.log("event..",event);
                jQuery('.multiselect__element').attr("aria-selected",false);
                const tp=  jQuery(this).find('.multiselect__option--highlight').parent();
                tp.attr("aria-selected",true);
                jQuery('.multiselect__input').attr("aria-activedescendant","");
                const cruId=tp.attr("id");
                jQuery(this).find('.multiselect__input').attr("aria-activedescendant",cruId);
               });

      // Code that will run only after the
      // entire view has been rendered
        if(jQuery(".ais-RefinementList").find(".multiselect__input").length){
            let multiselectInput=jQuery(".ais-RefinementList").find(".multiselect__input");
            let multiselectContent=jQuery(".ais-RefinementList").find(".multiselect__content");

            multiselectInput.attr("role","combobox");
            multiselectInput.attr("aria-autocomplete","list");
            multiselectContent.attr("role","list");
            multiselectInput.each((i,elm)=>{
                 jQuery(elm).attr("aria-controls",`optionselection_${i}`);
            });
            if(multiselectContent.length){
              multiselectContent.each((ulindx,obj)=>{
                  jQuery(obj).attr("id",`optionselection_${ulindx}`);
                   let collectAll= jQuery(obj).find('li');
                   collectAll.each((j,elmt_li)=>{
                      let cr=jQuery(elmt_li);
                      if(cr.hasClass("multiselect__element")){
                        cr.attr("role","option");
                        cr.attr("id",`option_${ulindx}_itemiId_${j}`);
                        cr.attr("aria-selected",false);
                      }else{
                        cr.attr("role","alert");
                      }

                  });
              });
            }
            jQuery('.multiselect__input').attr("aria-activedescendant","");
        }
      },500);

    });
  },
  methods: {
    buildUrlAndGo() {

      var query = '?';

      for (const attribute in this.selectedValues) {
        const value = this.selectedValues[attribute];
        for (var x = 0; x < value.length; x++) {
          let val = value[x].value;
          query += 'refinementList[' + attribute + ']=' + val + '&';
        }
      }

      const newUrl = this.targetPage + encodeURI(query.slice(0, -1));
      window.location.href = newUrl;
    },
    updateWhatWeHave(index) {
      let temp = JSON.parse(JSON.stringify(this.weHave));
      temp[index] = true;
      this.weHave = temp;
    },
    removeSelectedItems(items,index){
      if(index=="taxonomies.Tjänst"){
        let collection=[];

        items.forEach((element) => {
          let searchExclude=localize('searchExclude');
        // let exculdeElement=['Psykogeriatrik','Permanenta platser','Äldreboende'];
         let exculdeElement=searchExclude.split(",");
              if(!exculdeElement.includes(element.value)){
                collection.push(element);
              }
        } );

        return collection;
       // return  items.filter(items => !array.includes(['Psykogeriatrik']));;
      }else{
        return items;
      }

    }
    ,
    transformItems(items, index) {
      if (typeof this.weHave[index] === 'undefined') {
        this.updateWhatWeHave(index);
      }
     // console.log(this.removeSelectedItems(items,index));
        return this.removeSelectedItems(items,index);
     // return items;
    },
    itemOrder(Order){
      let orderType='desc';
     if(Order=="name"){
       orderType="asc";
     }else{
        orderType="desc";
     }
      return [Order+':'+orderType];
    }
  },
};
</script>
