/* eslint-disable prettier/prettier */
/* global svvSearchForm */
const localize = function(key) {
  if (typeof svvSearchForm === 'undefined') {
    return false;
  }

  if (typeof svvSearchForm[key] === 'undefined') {
    return false;
  }

  return svvSearchForm[key];
};

export default [
  {
    attribute: 'taxonomies.Tjeneste',
    labelKey: 'tjanstLabel',
    name: 'Tjanst',
    orderOn: localize('search_ServicesortOn') || 'name',
    vs: 'showthis',
  },
  {
    attribute: 'taxonomies.Fylke',
    labelKey: 'lanLabel',
    name: 'Lan',
    orderOn: localize('search_RegionsortOn') || 'name',
    vs: 'showthis',
  },
];
