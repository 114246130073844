import Vue from 'vue';
import SearchForm from './SearchForm.vue';
import InstantSearch from 'vue-instantsearch';

Vue.use(InstantSearch);

new Vue({
  el: '#svv_search_form',
  render: h => h(SearchForm), //search form (on Home page)
  created: () => {
    // eslint-disable-next-line
    console.log('Algolia search for Verksamheter: "verksamheter_search_form" init. ');
  },
});
